jQuery(function($) {
  // Remove the 'no-js' <body> class
  $("html").removeClass("no-js");

  //Disable pointer events on scroll
  var disablePointerEventsOnScroll = function() {
    var root = document.documentElement;
    var timer;

    window.addEventListener(
      "scroll",
      function() {
        // User scrolling so stop the timeout
        clearTimeout(timer);
        // Pointer events has not already been disabled.
        if (!root.style.pointerEvents) {
          root.style.pointerEvents = "none";
        }

        timer = setTimeout(function() {
          root.style.pointerEvents = "";
        }, 250);
      },
      false
    );
  };
  disablePointerEventsOnScroll();

  //THIS WILL ADD ROLE="PRESENTATION" TO SB CARD ACCORDION FOR ACCESSIBILITY
  $(".sb-card-accordion").map(function() {
    // console.log(this);
    $("span img").attr({
        // alt: "Accordion Imag",
        // title: "photo by Kelly Clark",
        role: "presentation"
      });
      //to test
      // $("span img").css( "background-color", "red" );
  }).get();

  //MAKES SURE EACH SEARCH QUERY GOES TO PROPER LANGUAGE SEARCH PAGE
  var lang = document.getElementsByTagName("html")[0].getAttribute("lang");

  if (lang == null) {
    return;
  }

  $('.elementor-search-form').submit(function (event) {
    if(document.querySelector('.sb-header-search-bar') !== null) {
      if (lang == 'en-US') {
        return;
      } else if (lang == 'es-MX') {
        $('.elementor-search-form').prop('action', '/es/');
      } else if (lang == 'vi') {
        $('.elementor-search-form').prop('action', '/vi/');
      } else {
        return;
      }
    }
  });

});

var callback = function(){
  // console.log("Mobile menu logic initiated");

  let mobileMenuIcon = document.getElementById("sb-mobile-menu-trigger");
  // let englishTrigger = document.getElementById("sb-mobile-menu-trigger");
  // let spanishTrigger = document.getElementById("sb-mobile-menu-trigger-spanish");

  // if(englishTrigger){
  //   mobileMenuIcon = englishTrigger;
  // } else {
  //   mobileMenuIcon = spanishTrigger;
  // }

  let mobileMenuOverlay = document.getElementById("sb-mobile-menu-overlay");
  let mobileMenuContainer = document.getElementById("sb-mobile-menu-container");
  let backToMainMenu = document.getElementById("sb-mobile-menu-container__back-to-main-menu");

  function resetMenu(evt) {
    // console.log("resetMenu");

    let container = document.getElementById("sb-mobile-menu-container__nav-container");
    container.style.padding = "0px 0px 0px 45px";

    let mobileMenuCloseButton = document.getElementById("mobile-menu-close-button");
    let mobileMenuContainer = mobileMenuCloseButton.parentElement;

    mobileMenuContainer.style.top = "15px";
    
    let menuWithoutBorder = evt.currentTarget.menuWithoutBorder;
    let param = evt.currentTarget.param;
    let j = evt.currentTarget.j;
    let currentTriggerParent = evt.currentTarget.currentTriggerParent;
    let currentTriggerGrandparent = evt.currentTarget.currentTriggerGrandparent;
    let currentTriggerGrandparentChildren = evt.currentTarget.currentTriggerGrandparentChildren;
    let currentTriggerGreatGrandparent = evt.currentTarget.currentTriggerGreatGrandparent;
    let currentTriggerGreatGreatGrandparent = evt.currentTarget.currentTriggerGreatGreatGrandparent;
    let currentTriggerGreatGreatGrandparentChildren = evt.currentTarget.currentTriggerGreatGreatGrandparentChildren;
    currentTriggerGrandparentChildren = currentTriggerGrandparent.children;

    menuWithoutBorder.style.borderBottom = "1px solid rgba(0,0,0,.2)";
    let englishArrowsExist = document.querySelector('#menu-mobile-menu .menu-item-has-children') !== null;
    let spanishArrowsExist = document.querySelector('#menu-mobile-menu-spanish .menu-item-has-children') !== null;
    let vietnameseArrowsExist = document.querySelector('#menu-mobile-menu-vietnamese .menu-item-has-children') !== null;

    let allEnglishTopLevelArrows = document.querySelectorAll("#menu-mobile-menu .menu-item-has-children");
    let allSpanishTopLevelArrows = document.querySelectorAll("#menu-mobile-menu-spanish .menu-item-has-children");
    let allVietnameseTopLevelArrows = document.querySelectorAll("#menu-mobile-menu-vietnamese .menu-item-has-children");

    let allTopLevelArrows;

    if (englishArrowsExist) {
      allTopLevelArrows = allEnglishTopLevelArrows;
    } else if (spanishArrowsExist) {
      allTopLevelArrows = allSpanishTopLevelArrows;
    } else if (vietnameseArrowsExist) {
      allTopLevelArrows = allVietnameseTopLevelArrows;
    }

    for (let i = 0; i < allTopLevelArrows.length; i++) {
      allTopLevelArrows[i].classList.add('arrow-init');
    }

    backToMainMenu.firstChild.classList.remove('arrow-init');

    if (param == 1) {
      for (let k = 0; k < currentTriggerGrandparentChildren.length; k++) {
        currentTriggerGrandparentChildren[k].style.display = "block";
      }
  
      //RESET TO INIT VALUES
      currentTriggerGreatGreatGrandparentChildren[j].style.display = "block";
      currentTriggerGrandparentChildren[j].style.display = "block";
      currentTriggerGreatGrandparent.firstChild.style.display = "block";
      currentTrigger.nextElementSibling.style.display = "none";
      currentTriggerGreatGreatGrandparentChildren[j].style.display = "block";
      currentTriggerGrandparentChildren[j].style.display = "block";
      currentTriggerGreatGrandparent.firstChild.style.display = "block";
      currentTrigger.nextElementSibling.style.display = "none";

      let englishMenu = document.getElementById("menu-mobile-menu");
      let spanishMenu = document.getElementById("menu-mobile-menu-spanish");
      let vietnameseMenu = document.getElementById("menu-mobile-menu-vietnamese");
      let allMenuItems;

      if (englishMenu) {
        englishMenu.style.display = "flex";
        allMenuItems = englishMenu.children;
      } else if (spanishMenu) {
        spanishMenu.style.display = "flex";
        allMenuItems = spanishMenu.children;
      } else if (vietnameseMenu) {
        vietnameseMenu.style.display = "flex";
        allMenuItems = vietnameseMenu.children;
      }
  
      for (let k = 0; k < allMenuItems.length; k++) {
        allMenuItems[k].style.display = "block";
      }
  
      let collapsibleMenus = document.querySelectorAll(".menu-item-has-children .sub-menu");
  
      // HIDE SUBMENUS ON INIT
      for (let i = 0; i < collapsibleMenus.length; i++) {
        let currentCollapsibleMenu = collapsibleMenus[i];
        currentCollapsibleMenu.style.display = "none";
      }
    } else if (param == 2) {

      for (let k = 0; k < currentTriggerGrandparentChildren.length; k++) {
        currentTriggerGrandparentChildren[k].style.display = "block";
      }
  
      //RESET TO INIT VALUES
      currentTriggerGreatGreatGrandparentChildren[j].style.display = "block";
      currentTriggerGrandparentChildren[j].style.display = "block";
      currentTriggerGreatGrandparent.firstChild.style.display = "block";
      currentTrigger.nextElementSibling.style.display = "none";
      currentTriggerGreatGreatGrandparentChildren[j].style.display = "block";
      currentTriggerGrandparentChildren[j].style.display = "block";
      currentTriggerGreatGrandparent.firstChild.style.display = "block";
      currentTrigger.nextElementSibling.style.display = "none";

      let englishMenu = document.getElementById("menu-mobile-menu");
      let spanishMenu = document.getElementById("menu-mobile-menu-spanish");
      let vietnameseMenu = document.getElementById("menu-mobile-menu-vietnamese");
      let allMenuItems;

      if (englishMenu) {
        englishMenu.style.display = "flex";
        allMenuItems = englishMenu.children;
      } else if (spanishMenu) {
        spanishMenu.style.display = "flex";
        allMenuItems = spanishMenu.children;
      } else if (vietnameseMenu) {
        vietnameseMenu.style.display = "flex";
        allMenuItems = vietnameseMenu.children;
      }
    
      for (let k = 0; k < allMenuItems.length; k++) {
        allMenuItems[k].style.display = "block";
      }
  
      let collapsibleMenus = document.querySelectorAll(".menu-item-has-children .sub-menu");

      // HIDE SUBMENUS ON INIT
      for (let i = 0; i < collapsibleMenus.length; i++) {
        let currentCollapsibleMenu = collapsibleMenus[i];
        currentCollapsibleMenu.style.display = "none";
      }
    }
  }

  function openSecondMenu(currentTriggerArg) {
    // console.log("openSecondMenu");

    let mobileMenuCloseButton = document.getElementById("mobile-menu-close-button");
    let mobileMenuContainer = mobileMenuCloseButton.parentElement;

    mobileMenuContainer.style.top = "23px";

    let container = document.getElementById("sb-mobile-menu-container__nav-container");
    container.style.padding = "0px 0px 0px 20px";


    let currentTrigger = currentTriggerArg;
    let param = 1;

    let englishMenu = document.getElementById("menu-mobile-menu");
    let spanishMenu = document.getElementById("menu-mobile-menu-spanish");
    let vietnameseMenu = document.getElementById("menu-mobile-menu-vietnamese");
    let allTopLevelArrows;
    let allNestedArrows;

    if (englishMenu) {
      allTopLevelArrows = document.querySelectorAll("#menu-mobile-menu .menu-item-has-children");
      allNestedArrows = document.querySelectorAll("#menu-mobile-menu li .menu-item-has-children");
    } else if (spanishMenu) {
      allTopLevelArrows = document.querySelectorAll("#menu-mobile-menu-spanish .menu-item-has-children");
      allNestedArrows = document.querySelectorAll("#menu-mobile-menu-spanish li .menu-item-has-children");
    } else if (vietnameseMenu) {
      allTopLevelArrows = document.querySelectorAll("#menu-mobile-menu-vietnamese .menu-item-has-children");
      allNestedArrows = document.querySelectorAll("#menu-mobile-menu-vietnamese li .menu-item-has-children");
    }

    for (let i = 0; i < allTopLevelArrows.length; i++) {
      allTopLevelArrows[i].classList.remove('arrow-init');
    }

    for (let i = 0; i < allNestedArrows.length; i++) {
      allNestedArrows[i].classList.add('arrow-init');
    }

    let nestedMenus = document.querySelectorAll(".menu-item-has-children .sub-menu");

    for (let i = 0; i < nestedMenus.length; i++) {
      nestedMenus[i].style.padding = "0px 0px 0px 20px";
    }

    //HERE
    if (currentTrigger.nextElementSibling.style.display == "none") {
      currentTrigger.nextElementSibling.style.display = "block";
    } else {
      currentTrigger.nextElementSibling.style.display = "none";
    }

    //GET PARENTS AND GRANDPARENTS 
    currentTriggerParent = currentTrigger.parentElement;
    currentTriggerGrandparent = currentTriggerParent.parentElement;
    currentTriggerGrandparentChildren = currentTriggerGrandparent.children;
    currentTriggerGreatGrandparent = currentTriggerGrandparent.parentElement;
    currentTriggerGreatGreatGrandparent = currentTriggerGreatGrandparent.parentElement;
    currentTriggerGreatGreatGrandparentChildren = currentTriggerGreatGreatGrandparent.children;

    //HERE
    for (let j = 0; j < currentTriggerGreatGreatGrandparentChildren.length; j++) {
      if (currentTriggerGreatGreatGrandparentChildren[j] !== currentTriggerGreatGrandparent) {
        currentTriggerGreatGreatGrandparentChildren[j].style.display = "none";
      }
    }

    let fourthLevelMenus = document.querySelectorAll(".menu-item-has-children .menu-item-has-children .menu-item-has-children .sub-menu");

    for (let j = 0; j < fourthLevelMenus.length; j++) {
      let currentFourthLevelMenu = fourthLevelMenus[j];

      // if trigger clicked is equal to sibling anchor, display block
      if (currentTrigger == currentFourthLevelMenu.previousElementSibling) {
        param = 2;

        let currentTriggerSiblingMenu = currentTrigger.nextElementSibling;
        let currentTriggerSiblingMenuListItems = currentTriggerSiblingMenu.children;
    
        for (let i = 0; i < currentTriggerSiblingMenuListItems.length; i++) {
          currentlistItem = currentTriggerSiblingMenuListItems[i];
          currentlistItem.style.borderBottom = "1px solid rgba(0,0,0,.2)";
        }
    
        // //toggle menu on/off
        if (currentFourthLevelMenu.style.display == "none") {
          currentFourthLevelMenu.style.display = "block";

        } else if (currentFourthLevelMenu.style.display == "block") {
          currentFourthLevelMenu.style.display = "none";
        }
        
        let backToMainMenu = document.getElementById("sb-mobile-menu-container__back-to-main-menu");
        backToMainMenu.addEventListener("click", resetMenu, false);
        backToMainMenu.param = param;
        return;
      }
    }

    let menuWithoutBorder = currentTrigger.parentElement;
    currentTrigger.parentElement.style.border = "none";
   
    for (let j = 0; j < currentTriggerGrandparentChildren.length; j++) {

      if (currentTriggerGrandparentChildren[j] !== currentTriggerParent) {
        currentTriggerGrandparentChildren[j].style.display = "none";
        currentTriggerGreatGrandparent.firstChild.style.display = "none";
        currentTrigger.nextElementSibling.style.display = "block";
        let backToMainMenu = document.getElementById("sb-mobile-menu-container__back-to-main-menu");
        backToMainMenu.firstChild.classList.add('arrow-init');

        backToMainMenu.addEventListener("click", resetMenu, false);
        backToMainMenu.menuWithoutBorder = menuWithoutBorder;
        backToMainMenu.param = param;
        backToMainMenu.j = j;
        backToMainMenu.currentTriggerParent = currentTriggerParent;
        backToMainMenu.currentTriggerGrandparent = currentTriggerGrandparent;
        backToMainMenu.currentTriggerGrandparentChildren = currentTriggerGrandparentChildren;
        backToMainMenu.currentTriggerGreatGrandparent = currentTriggerGreatGrandparent;
        backToMainMenu.currentTriggerGreatGreatGrandparent = currentTriggerGreatGreatGrandparent;
        backToMainMenu.currentTriggerGreatGreatGrandparentChildren = currentTriggerGreatGreatGrandparentChildren;
      }
    }
  }

  //HERE
  function hideSubMenus(evt) {
    // console.log("hideSubMenus");

    currentTrigger = evt.currentTarget.myParam;

    if (!currentTrigger.nextElementSibling) {
      return;
    } 

    let currentTriggerSiblingMenu = currentTrigger.nextElementSibling;
    let currentTriggerSiblingMenuListItems = currentTriggerSiblingMenu.children;

    for (let i = 0; i < currentTriggerSiblingMenuListItems.length; i++) {
      currentlistItem = currentTriggerSiblingMenuListItems[i];
      currentlistItem.style.border = "none";
    }


    //submenu next to TEXAS CHIP
    let thirdLevelSubMenu = currentTrigger.nextElementSibling;
    
    //all submenu li's
    let thirdLevelSubMenuListItems =  thirdLevelSubMenu.children;
    
    //check to see if li contains ul
    for (let j = 0; j < thirdLevelSubMenuListItems.length; j++) {
      let ulFound = false;
      let nestedUL;
      let anchorElement;

      // check sub menu children lis for ul
      for (let k = 0; k < thirdLevelSubMenuListItems[j].children.length; k++) {
        let currentChild = thirdLevelSubMenuListItems[j].children[k];
        let currentChildConstructorType = thirdLevelSubMenuListItems[j].children[k].constructor.name;

        if (currentChildConstructorType == "HTMLAnchorElement") {
          anchorElement = currentChild;
        }

        if (currentChildConstructorType == "HTMLUListElement") {
          ulFound = true;
          nestedUL = thirdLevelSubMenuListItems[j].children[k];
          break;
        }
      }
    }
    openSecondMenu(currentTrigger);
  }

  function toggleMenus(evt) {
    // console.log("toggleMenus");

    let firstCurrentTrigger = evt.currentTarget.firstCurrentTrigger;

    //ON CLICK, EITHER SHOW OR HIDE SUBMENUS    
    if (!firstCurrentTrigger.nextElementSibling) {
      return;
    } 

    let firstCurrentTriggerSiblingMenu = firstCurrentTrigger.nextElementSibling;

    for (let i = 0; i < firstCurrentTriggerSiblingMenu.children.length; i++) {
      firstCurrentTriggerSiblingMenu.children[i].style.display = "block";
    }

    if (firstCurrentTriggerSiblingMenu.style.display == "block" || firstCurrentTriggerSiblingMenu.style.display == "flex") {
      firstCurrentTriggerSiblingMenu.style.display = "none";
    } else if (firstCurrentTriggerSiblingMenu.style.display == "none") {
      firstCurrentTriggerSiblingMenu.style.display = "block";
    }
  
  }

  function getAllClickEvents() {
    // console.log("getAllClickEvents");

    //GET ALL LINKS THAT OPEN MENUS
    let collapsibleMenuTriggers = document.querySelectorAll(".menu-item-has-children a");

    for (let i = 0; i < collapsibleMenuTriggers.length; i++) {
      let firstCurrentTrigger = collapsibleMenuTriggers[i];
  
      //ON CLICK, EITHER SHOW OR HIDE SUBMENUS
      firstCurrentTrigger.addEventListener("click", toggleMenus);
      firstCurrentTrigger.firstCurrentTrigger = firstCurrentTrigger;
    }

    let nestedTriggers = document.querySelectorAll(".menu-item-has-children .menu-item-has-children a");

    for (let i = 0; i < nestedTriggers.length; i++) {
      let currentTrigger = nestedTriggers[i];
      currentTrigger.addEventListener("click", hideSubMenus, false);
      currentTrigger.myParam = currentTrigger;
    }
    
  }

  function setInitState(obj) {
    // console.log("setInitState");

    let mobileMenuOverlay = obj.mobileMenuOverlay;
    let mobileMenuContainer = obj.mobileMenuContainer;
    // let backToMainMenu = obj.backToMainMenu;
    let allMenuItems = obj.allMenuItems;
    let collapsibleMenuTriggersAnchors = obj.collapsibleMenuTriggersAnchors;
    let collapsibleMenus = obj.collapsibleMenus;

    let container = document.getElementById("sb-mobile-menu-container__nav-container");
    container.style.padding = "0px 0px 0px 45px";

    mobileMenuOverlay.style.display = "flex";
    mobileMenuContainer.style.display = "flex";

    for (let k = 0; k < allMenuItems.length; k++) {
      allMenuItems[k].style.display = "block";
    }

    for (let i = 0; i < collapsibleMenuTriggersAnchors.length; i++) {
      collapsibleMenuTriggersAnchors[i].style.display = "block";
    }

    // HIDE SUBMENUS ON INIT
    for (let i = 0; i < collapsibleMenus.length; i++) {
      let currentCollapsibleMenu = collapsibleMenus[i];
      currentCollapsibleMenu.style.display = "none";
    }
  }

  //HERE
  function getAllValues() {
    // console.log("getAllValues");

    let englishMenu = document.getElementById("menu-mobile-menu");
    let spanishMenu = document.getElementById("menu-mobile-menu-spanish");
    let vietnameseMenu = document.getElementById("menu-mobile-menu-vietnamese");
    let allTopLevelArrows;
    let allNestedArrows;

    if (englishMenu) {
      allTopLevelArrows = document.querySelectorAll("#menu-mobile-menu .menu-item-has-children");
      allNestedArrows = document.querySelectorAll("#menu-mobile-menu li .menu-item-has-children");
    } else if (spanishMenu) {
      allTopLevelArrows = document.querySelectorAll("#menu-mobile-menu-spanish .menu-item-has-children");
      allNestedArrows = document.querySelectorAll("#menu-mobile-menu-spanish li .menu-item-has-children");
    } else if (vietnameseMenu) {
      allTopLevelArrows = document.querySelectorAll("#menu-mobile-menu-vietnamese .menu-item-has-children");
      allNestedArrows = document.querySelectorAll("#menu-mobile-menu-vietnamese li .menu-item-has-children");
    }

    for (let i = 0; i < allTopLevelArrows.length; i++) {
      allTopLevelArrows[i].classList.add('arrow-init');
    }

    for (let i = 0; i < allNestedArrows.length; i++) {
      allNestedArrows[i].classList.add('arrow-init');
    }

    // .arrow-init

    let mobileMenuOverlay = document.getElementById("sb-mobile-menu-overlay");
    let mobileMenuContainer = document.getElementById("sb-mobile-menu-container");
    let backToMainMenu = document.getElementById("sb-mobile-menu-container__back-to-main-menu");
    backToMainMenu.firstChild.classList.remove('arrow-init');

    let allMenuItems;

    //MAKE SURE ALL MENU ITEMS ARE VISIBLE

    if (englishMenu) {
      englishMenu.style.display = "flex";
      allMenuItems = englishMenu.children;
    } else if (spanishMenu) {
      spanishMenu.style.display = "flex";
      allMenuItems = spanishMenu.children;
    } else if (vietnameseMenu) {
      vietnameseMenu.style.display = "flex";
      allMenuItems = vietnameseMenu.children;
    }

    let collapsibleMenuTriggersAnchors = document.querySelectorAll(".menu-item-has-children a");
  

    //GET ALL COLLAPSIBLE MENUS
    let collapsibleMenus = document.querySelectorAll(".menu-item-has-children .sub-menu");
  
    let initStateObj = {
      mobileMenuOverlay: mobileMenuOverlay,
      mobileMenuContainer: mobileMenuContainer,
      backToMainMenu: backToMainMenu,
      allMenuItems: allMenuItems,
      collapsibleMenuTriggersAnchors: collapsibleMenuTriggersAnchors,
      collapsibleMenus: collapsibleMenus
    }

    setInitState(initStateObj);
    getAllClickEvents();

    // // //REMOVE EVENT LISTENERS
    // mobileMenuIcon.removeEventListener('click', getAllValues);
  }

  // WHEN MENU ICON IS CLICKED
  if (!mobileMenuIcon) {
    return;
  }

  mobileMenuIcon.addEventListener("click", getAllValues);
  
  mobileMenuOverlay.addEventListener("click", function() {
    // console.log("mobileMenuOverlay.addEventListener");

    mobileMenuOverlay.style.display = "none";
    mobileMenuContainer.style.display = "none";

    let englishMenu = document.getElementById("menu-mobile-menu");
    let spanishMenu = document.getElementById("menu-mobile-menu-spanish");
    let vietnameseMenu = document.getElementById("menu-mobile-menu-vietnamese");
    let allMenuItems;

    if (englishMenu) {
      englishMenu.style.display = "block";
      allMenuItems = englishMenu.children;
    } else if (spanishMenu) {
      spanishMenu.style.display = "block";
      allMenuItems = spanishMenu.children;
    } else if (vietnameseMenu) {
      vietnameseMenu.style.display = "block";
      allMenuItems = vietnameseMenu.children;
    }

    for (let k = 0; k < allMenuItems.length; k++) {
      allMenuItems[k].style.display = "flex";
    }
  
  });

  let mobileMenuCloseButton = document.getElementById("mobile-menu-close-button");

  mobileMenuCloseButton.addEventListener("click", function() {
    // console.log("mobileMenuOverlay.addEventListener");

    mobileMenuOverlay.style.display = "none";
    mobileMenuContainer.style.display = "none";

    let englishMenu = document.getElementById("menu-mobile-menu");
    let spanishMenu = document.getElementById("menu-mobile-menu-spanish");
    let vietnameseMenu = document.getElementById("menu-mobile-menu-vietnamese");
    let allMenuItems;

    if (englishMenu) {
      englishMenu.style.display = "block";
      allMenuItems = englishMenu.children;
    } else if (spanishMenu) {
      spanishMenu.style.display = "block";
      allMenuItems = spanishMenu.children;
    } else if (vietnameseMenu) {
      vietnameseMenu.style.display = "block";
      allMenuItems = vietnameseMenu.children;
    }

    for (let k = 0; k < allMenuItems.length; k++) {
      allMenuItems[k].style.display = "flex";
    }
  
  });

};

if (
    document.readyState === "complete" ||
    (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
  callback();
} else {
  document.addEventListener("DOMContentLoaded", callback);
}
